import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles.css';

const ReviewerSignup = () => {
  const [formData, setFormData] = useState({
    name: '',
    github: '',
    skills: '',
    availability: '',
    email: '',
    discordHandle: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/reviewer-signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      if (response.ok) {
        setSubmitted(true);
      } else {
        console.error('Submission failed:', response.status, data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleNavigateToDiscord = () => {
    window.open('https://discord.gg/m2Qud5GDqp', '_blank');
  };

  if (submitted) {
    return (
      <div>
        <Header />
        <div className="container">
          <h2>Thank you for applying!</h2>
          <p>Thanks for applying to become a Code Reviewer at BasedAgent. A member of our team will be in touch to review your application and reach out to you if there is a fit.</p>
          <button type="button" onClick={handleNavigateToDiscord}>Join Our Discord</button>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="container">
        <h1>Become a Reviewer</h1>
        <form onSubmit={handleSubmit} className="reviewer-form">
          <input 
            type="text" 
            name="name" 
            placeholder="Name" 
            onChange={handleChange} 
            required 
          />
          <input 
            type="text" 
            name="github" 
            placeholder="GitHub Profile" 
            onChange={handleChange} 
            required 
          />
          <textarea 
            name="skills" 
            placeholder="Skills (e.g., Python, TensorFlow, Solidity)" 
            onChange={handleChange} 
            required
          ></textarea>
          <input 
            type="text" 
            name="availability" 
            placeholder="Availability (hours per week)" 
            onChange={handleChange} 
            required 
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Email" 
            onChange={handleChange} 
            required 
          />
          <input 
            type="text" 
            name="discordHandle" 
            placeholder="Discord Handle" 
            onChange={handleChange} 
            required 
          />
          <button type="submit">Submit Application</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewerSignup;

