import Navbar from "./navigation/Navbar";

function Header() {

  return (
    <Navbar />
  );
}

export default Header;
